import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Sidebar from "../../img/sidebar.png";
import Ecommerce from "../../img/ecommerce.png";
import HOC from "../../img/hoc.png";
import medical1 from "../../img/medical1.jpg";
import medical2 from "../../img/medical2.jpg";
import medical3 from "../../img/medical3.jpg";
import shop1 from "../../img/shop1.jpg";
import shop2 from "../../img/shop2.jpg";
import travel from "../../img/travel.jpg";
import realstate from "../../img/realstate.jpg";

import MusicApp from "../../img/musicapp.png";
import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{ color: darkMode ? "white" : "" }}>Recent Projects</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
         <SwiperSlide>
          <img src={medical2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={shop1} alt="" />
        </SwiperSlide>
         <SwiperSlide>
          <img src={medical3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={travel} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={realstate} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={shop2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={medical1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Sidebar} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Ecommerce} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={MusicApp} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={HOC} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
