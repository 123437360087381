import React, { useContext } from "react";
import "./Services.css";
import Card from "../Card/Card";
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import Resume from "./resume.pdf";

const Services = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };

  return (
    <div className="services" id="services">
      {/* left side */}
      <div className="awesome">
        {/* dark mode */}
        <span style={{ color: darkMode ? "white" : "" }}>My Awesome</span>
        <span>services</span>
        <span>
          I offer a wide range of mobile app and web development services across
          various industries.<br/> For clinics and healthcare, I create custom apps
          that streamline patient management,<br/> appointments, and telemedicine,
          enhancing both operational efficiency and patient care. In the<br/>
          education sector, I develop e-learning platforms and mobile apps to
          facilitate virtual<br/> classrooms and student-teacher interaction. For
          e-commerce businesses, I design powerful retail apps that offer<br/>
          seamless navigation, secure payments, and integrated inventory<br/>
          management. I also build fitness and wellness apps that feature<br/>
          workout tracking, nutrition plans, and virtual training. In the<br/>
          logistics sector, my apps improve delivery management through<br/>
          real-time tracking and route optimization. Additionally, I offer<br/>
          custom web development for businesses, creating responsive,<br/>
          high-performance websites, along with CMS solutions on platforms like<br/>
          WordPress and Shopify, giving clients full control over their online
          presence.
        </span>

        {/* <a href={Resume} download>
          <button className="button s-button">Download CV</button>
        </a> */}
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>
      {/* right */}
      <div className="cards">
        {/* first card */}
        <motion.div
          initial={{ left: "25rem" }}
          whileInView={{ left: "14rem" }}
          transition={transition}
        >
          <Card
            emoji={HeartEmoji}
            heading={"Design"}
            detail={"Figma, Sketch, Photoshop, Adobe Illustrator, Adobe xd"}
          />
        </motion.div>
        {/* second card */}
        <motion.div
          initial={{ left: "-11rem", top: "12rem" }}
          whileInView={{ left: "-4rem" }}
          transition={transition}
        >
          <Card
            emoji={Glasses}
            heading={"Developer"}
            detail={"Html, Css, JavaScript, React,React Native Nodejs, Express"}
          />
        </motion.div>
        {/* 3rd */}
        <motion.div
          initial={{ top: "19rem", left: "25rem" }}
          whileInView={{ left: "12rem" }}
          transition={transition}
        >
          <Card
            emoji={Humble}
            heading={"UI/UX"}
            detail={
              "Framer,Principle,Marvel"
            }
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        <div
          className="blur s-blur2"
          style={{ background: "var(--purple)" }}
        ></div>
      </div>
    </div>
  );
};

export default Services;
