import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";
import profilePic1 from "../../img/profile1.jpg";
import profilePic2 from "../../img/profile2.jpg";
import profilePic3 from "../../img/profile3.jpg";
import profilePic4 from "../../img/profile4.jpg";
import profilePic5 from "../../img/profile5.jpg"; // Add new profile pics as needed
import profilePic6 from "../../img/profile6.jpg"; // Add new profile pics as needed
import profilePic7 from "../../img/profile7.jpg"; // Add new profile pics as needed
import profilePic8 from "../../img/profile8.jpg"; // Add new profile pics as needed
import profilePic9 from "../../img/profile9.jpg"; // Add new profile pics as needed
import profilePic10 from "../../img/profile10.jpg"; // Add new profile pics as needed

const Testimonial = () => {
  const clients = [
    {
      img: profilePic1,
      review:
        "Sarran transformed our clinic's operations with his custom app. Patient engagement has improved significantly, and we’ve seen a noticeable reduction in no-shows. Highly recommend his services!",
      name: "Dr. Lisa Wong, Gynecologist",
    },
    {
      img: profilePic2,
      review:
        "The app Sarran developed for us is a game changer! It provides our patients with 24/7 access to information and services, enhancing their overall experience at our clinic.",
      name: "Dr. Adrian Mondry, Internal Medicine Specialist",
    },
    {
      img: profilePic3,
      review:
        "Sarran's expertise in mobile app development is evident in the quality of work he delivers. Our patients love the user-friendly interface and the personalized reminders feature.",
      name: "Dr. Ong Kian Chung, Respiratory Physician",
    },
    {
      img: profilePic4,
      review:
        "Working with Sarran was a smooth experience from start to finish. He understood our needs perfectly and tailored the app to fit our clinic's specific requirements. Great results!",
      name: "Dr. Jenell Decker, Aesthetic Medicine Specialist",
    },
    {
      img: profilePic5,
      review:
        "Our e-commerce platform skyrocketed in sales after implementing the app Sarran developed. The user interface is sleek, and the checkout process is seamless. Highly recommended for online businesses!",
      name: "Emily Chen, CEO of TrendyGoods",
    },
    {
      img: profilePic6,
      review:
        "As a fitness coach, I needed an app that could keep my clients engaged and accountable. Sarran delivered exactly what I was looking for! The custom reminders and workout tracking features have been a hit.",
      name: "Mike Johnson, Fitness Coach",
    },
    {
      img: profilePic7,
      review:
        "Sarran’s mobile app has revolutionized our telehealth services. Patients can now book appointments easily, and our workflow is much more efficient. A fantastic investment!",
      name: "Dr. Sarah Lee, Telehealth Specialist",
    },
    {
      img: profilePic8,
      review:
        "Our gym's membership grew by 30% since launching the app Sarran built. The nutrition tracking and class scheduling features are fantastic!",
      name: "Chris Miller, Gym Owner",
    },
    {
      img: profilePic9,
      review:
        "Sarran's attention to detail and commitment to quality is impressive. Our pharmacy app has improved customer interactions significantly.",
      name: "Nina Patel, Pharmacy Manager",
    },
    {
      img: profilePic10,
      review:
        "The app Sarran created for our retail business has enhanced customer engagement and increased sales. It's a must-have for any modern retailer!",
      name: "James Wong, Retail Business Owner",
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from me...</span>
        <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
        <div className="blur t-blur2" style={{ background: "skyblue" }}></div>
      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span>{client.review}</span>
                <h5>{client.name}</h5>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;
