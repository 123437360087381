import React, { useContext, useState } from "react";
import "./Contact.css";
import { useForm, ValidationError } from '@formspree/react';
import { themeContext } from "../../Context";

const Contact = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // Using Formspree for form handling
  const [state, handleSubmit] = useForm("mwpedyro"); // Replace with your Formspree form ID

  // Local state for form fields
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSuccess = () => {
    setFormData({ user_name: '', user_email: '', message: '' }); // Reset fields
  };

  return (
    <div className="contact-form" id="contact">
      {/* Left side section */}
      <div className="w-left">
        <div className="awesome">
          {/* Dark mode styling */}
          <span style={{ color: darkMode ? 'white' : '' }}>Get in Touch</span>
          <span>Contact me</span>
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>
      </div>

      {/* Right side form */}
      <div className="c-right">
        <form onSubmit={(e) => {
          handleSubmit(e);
          if (state.succeeded) {
            handleSuccess();
          }
        }}>
          <input
            type="text"
            name="user_name"
            className="user"
            placeholder="Name"
            value={formData.user_name}
            onChange={handleChange}
          />
          <ValidationError 
            prefix="Name" 
            field="user_name" 
            errors={state.errors}
          />
          <input
            type="email"
            name="user_email"
            className="user"
            placeholder="Email"
            value={formData.user_email}
            onChange={handleChange}
          />
          <ValidationError 
            prefix="Email" 
            field="user_email" 
            errors={state.errors}
          />
          <textarea
            name="message"
            className="user"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
          />
          <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
          />
          <input 
            type="submit" 
            value="Send" 
            className="button" 
            disabled={state.submitting}
          />
          {/* Show a message if the form was successfully submitted */}
          <span>{state.succeeded && "Thanks for Contacting me!"}</span>
          <div
            className="blur c-blur1"
            style={{ background: "var(--purple)" }}
          ></div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
